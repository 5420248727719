export default class ScrollToTop {

    #cssStyleButtonScrollToTop = '';
    #cssStyleButtonScrollToTopDisplayNone = 'display: none;';
    #cssStyleButtonScrollToTopDisplayBlock = 'display: block;';

    constructor(
        idButtonScroll = "scroll-to-top",
        elementContainButton = "body",
        classButton = "btn-primary",
        classIcon = "fas fa-arrow-up",
        text = "Remonter en haut de page"
    ) {
        this.idButtonScroll = idButtonScroll;
        this.elementContainButton = elementContainButton;
        this.classButton = classButton;
        this.classIcon = classIcon;
        this.text = text;
        this.addButton();
        this.eventOnscroll();
    }

    eventOnscroll() {
        const instance = this;
        let mybutton = document.querySelector("#" + this.idButtonScroll);
        window.onscroll = function () { instance.onScroll(mybutton) };
    }

    eventOnclick() {
        let scrollTopTop = document.querySelector("#" + this.idButtonScroll);

        scrollTopTop.addEventListener("click", function (e) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        });
    }

    template() {
        const result = `
            <button 
                id="${this.idButtonScroll}" 
                title="${this.text}" 
                class="${this.classButton}" 
                style="${this.#cssStyleButtonScrollToTopDisplayNone} ${this.#cssStyleButtonScrollToTop}"
                >
                <span class="sr-only">${this.text}</span>
                <i class="${this.classIcon}" aria-hidden="true"></i>
            </button>
        `;

        return result;
    }

    addButton() {
        jQuery(this.elementContainButton).append(this.template());
        this.eventOnclick();
    }

    onScroll(mybutton) {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            mybutton.style.cssText = this.#cssStyleButtonScrollToTopDisplayBlock + this.#cssStyleButtonScrollToTop;
        } else {
            mybutton.style.cssText = this.#cssStyleButtonScrollToTopDisplayNone + this.#cssStyleButtonScrollToTop;
        }
    }

}