import MainMenu from "./MainMenu.js";

export default class Menu {
    static init() {

        const btnNavBurger = jQuery('#nav-burger');
        const divNavBurger = jQuery('#main-nav-mobile-main');

        if (btnNavBurger != undefined && divNavBurger != undefined) {
            if (btnNavBurger.length > 0 && divNavBurger.length > 0) {

                try {
                    new MainMenu(btnNavBurger, divNavBurger);
                }
                catch (e) {
                    console.log("Une erreur est survenue dans le module MainMenu");
                }

            }
        }
    }
}