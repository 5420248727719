import AkoTools from "./jAko/AkoTools";
import Menu from "./jAko/menu/Menu";
import ScrollToTop from "./jAko/ScrollToTop";

//Menu
Menu.init();

/*#region scroll to top*/
new ScrollToTop(
    "scroll-to-top",
    "body",
    "btn btnsearch-rouge-cfa-c btn-radius bottom-6 right-0 position-fixed z-index-10000 m-2 ",
    "fas fa-arrow-up",
    "Remonter en haut de page"
);
/*#endregion */
       
        var button = $('#pauseButton');
        var carroussel = $('#carouselStructures');
       

        var cookieValue = AkoTools.getCookie('isPausedCookie');
        if (cookieValue.trim() !== "") {
            try {
                var isNotPausedCookie = JSON.parse(cookieValue);
             
                playPauseCarrousel(isNotPausedCookie);
            } catch (error) {
             
                console.error("Erreur lors de l'analyse JSON du cookie 'isPausedCookie':", error);
            }
        } else {
            
            AkoTools.setCookie('isPausedCookie', true);
            playPauseCarrousel(true); 
        }
        // var isNotPausedCookie = JSON.parse(AkoTools.getCookie('isPausedCookie'));
       
        // if(isNotPausedCookie == undefined || isNotPausedCookie == null || isNotPausedCookie == ""){
        //     AkoTools.setCookie('isPausedCookie', true);
        // }
        
        // isNotPausedCookie = JSON.parse(AkoTools.getCookie('isPausedCookie'));
        // console.log(isNotPausedCookie);
        // playPauseCarrousel(isNotPausedCookie);

        // fonction pour basculer l'état du carrousel
        function toggleCarousel() {
            // pour interpréter la chaine de caractère en booléen : JSON.parse()
            var isNotPausedCookie2 = JSON.parse(AkoTools.getCookie('isPausedCookie'));
            isNotPausedCookie2 = !isNotPausedCookie2;

            AkoTools.setCookie('isPausedCookie', isNotPausedCookie2);
            
            playPauseCarrousel(isNotPausedCookie2);
        }

        function playPauseCarrousel(isPlayPause){

            if (isPlayPause == true) {
                // Si le cookie n'existe pas, créer le cookie avec la valeur par défaut
                carroussel.carousel('cycle');
                button.text('Pause');
            }else 
            {
                // Restaurer l'état du carrousel en fonction de la valeur du cookie
                carroussel.carousel('pause');
                button.text('Jouer');
            }
        }

        // gérez l'état au clic sur le bouton.
        button.on('click', function() {
            toggleCarousel();
        });

