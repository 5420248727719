export default class AkoTools {
    static isEmpty(field) {
        let empty = true;

        if (field != undefined && field != null && field != "" && field != [] && field != {}) {
            empty = false;
        }
        return empty;
    }
    static insertObjectToListInAcfField( data )
    {
        if( !this.isEmpty( data ) )
        {
            if( !this.isEmpty( data.objectList ) && data.objectList.length > 0 )
            {
                let newObjectList = [];

                data.objectList.forEach( obj => 
                    {
                        if( !this.isEmpty( obj.acf ) )
                        {
                            if( !this.isEmpty( obj.acf[data.propertyToInsertSubObject] ) )
                            {
                                if( !this.isEmpty( obj.acf[data.propertyToInsertSubObject] ) )
                                {
                                    let newSubObjectList = [];
                                    obj.acf[data.propertyToInsertSubObject].forEach( subObj => 
                                        {
                                            if( !this.isEmpty( subObj.ID ) )
                                            {
                                                newSubObjectList.push( this.getObjectById( data.subObjectList, subObj.ID ) );
                                            }
                                        }
                                    );
                                    obj.acf[data.propertyToInsertSubObject] = newSubObjectList;
                                }
                            }
                        }
                        newObjectList.push( obj );
                    }
                );
                return newObjectList;
            }
        }
    }

    static getObjectById( objectList, id )
    {
        let newObject = null;

        if( !this.isEmpty( objectList ) && !this.isEmpty( id ) )
        {
            objectList.forEach( object => 
                {
                    if( object.id == id ) 
                    {
                        newObject = object;
                    } 
                }
            );
        }

        return newObject;
    }
    
    static convertBooleanTo( data )
    {
        let val = "";
        if( data.string != undefined )
        {
            switch( data.string )
            {
                case true: 
                    val = data.true;
                break;
                case false: 
                    val = data.false;
                break;
            }
        }
        return val;
    }

    //#region concat lists
        static arrayToString( list, symbole, berforeObject, afterObject  )
        {
            if( !this.isEmpty( list ) && !this.isEmpty( symbole ) )
            {
                if( list.length > 0 )
                {
                    let val = "";
                    list.forEach( ( object, i ) => 
                        {
                            if( !this.isEmpty( berforeObject ) && !this.isEmpty( afterObject ) )
                            {
                                val += berforeObject;
                            }
                            
                            val += object;

                            if( !this.isEmpty( berforeObject ) && !this.isEmpty( afterObject ) )
                            {
                                val += afterObject;
                            }
                            
                            if( object.length > 1 && list.length > i + 1 )
                            {
                                val += symbole;
                            }
                        }
                    );
                    return val;
                }
            }
        }

        static objectPropertiesBoolToString( list, symbole, convertBool  )
        {
            if( list != undefined && symbole != undefined )
            {
                if( Object.entries( list ).length > 0 )
                {
                    let val = "";
                    let count = 0;
                    for ( const [key, value] of Object.entries( list ) ) 
                    {
                        val += key.replace(/_/g, " ").trim().replace(/^\w/, (c) => c.toUpperCase()) + " : " + this.convertBooleanTo( { string: value, true: convertBool.true, false: convertBool.false } );
                        if(  Object.entries( list ).length > 1 && Object.entries( list ).length > count + 1 )
                        {
                            val += symbole;
                        }
                        count++;
                    }
                    return val;
                }
            }
        }
    // ecrire un cookie
    static setCookie(cname, cvalue, exdays = 15) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    // récupérer un cookie
    static getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}