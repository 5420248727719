export default class MainMenu {

    #BtnBurger;
    #Nav;
    #idMainMenu = 'main-nav-mobile-main';
    #idBurgerBtn = 'nav-burger';
    #prevLink = null;
    #focusDirection = "";
    #isOpen = false;

    /*#region constructor */
    constructor(BtnBurger, Nav) {

        this.#BtnBurger = BtnBurger;
        this.#Nav = Nav;

        this.#setIsOpen();
        this.#setAriaLabelBurger();

        this.#onClick();
        this.#onFocus();
    }
    /*#endregion*/

    /*#region Accessor DOM */
    #getMenu() {
        return jQuery("#" + this.#idMainMenu);
    }

    #getLinksMenu() {
        return this.#getMenu().find(".nav-link");
    }

    #getLastLinkMenu() {
        return this.#getLinksMenu().last();
    }

    #getPrevLink() {
        return jQuery(this.#prevLink);
    }

    #getBurger() {
        return jQuery(this.#BtnBurger);
    }

    getTargetParentMenu(target) {
        return jQuery(target).parents("#" + this.#idMainMenu);
    }

    getTargetBtnMenu(target) {
        return jQuery(target).parents('#' + this.#idBurgerBtn);
    }

    getTargetId(target) {
        return jQuery(target).attr("id");
    }

    getTarget(target) {
        return jQuery(target);
    }

    getNavAriaExpanded() {
        return this.#getBurger().attr('aria-expanded');
    }

    getNavAriaLabel() {
        return this.#getBurger().attr('aria-label');
    }

    setNavAriaLabel($value) {
        this.#getBurger().attr('aria-label', $value);
    }

    #setAriaLabelBurger() {
        if (this.#isOpen == true) {
            this.setNavAriaLabel("Fermer le menu");
        }
        else {
            this.setNavAriaLabel("Ouvrir le menu");
        }
    }
    /*#endregion*/

    /*#region Accessors */
    #setPrevlink(link) {
        this.#prevLink = link;
    }

    #setDirection(e) {

        this.#focusDirection = "";

        if (e.key == 'Tab') {
            if (e.shiftKey == false) {
                this.#focusDirection = "down"
            }
            else if (e.shiftKey == true) {
                this.#focusDirection = "up"
            }
        }
    }

    #setIsOpen() {
        if (this.#checkMenuIsOpen() == true) {
            this.#isOpen = true;
        }
        else {
            this.#isOpen = false;
        }
    }
    /*#endregion*/

    /*#region event onfocus */
    #onFocus() {

        this.#getLinksMenu().on(
            "focusin keydown",
            (e) => {
                this.#setDirection(e);
                this.#setPrevlink(e.target);
            }
        );

        this.#getLinksMenu().on(
            "focusout",
            () => {
                if (this.#checkMenuIsOpen() && this.#focusIsDown() && this.#checkPrevLinkIsLastLink()) {
                    this.#getBurger().focus();
                }
            }
        );

        this.#getBurger().on(
            "focusout",
            () => {
                if (this.#checkMenuIsOpen() && this.#focusIsUp()) {
                    this.#getLastLinkMenu().focus();
                }
            }
        );
    }
    /*#endregion*/

    /*#region event onclick */
    #onClick() {
        jQuery(document.body).click((e) => {
            this.#toogleClickMenu(e.target);
        });
    }
    /*#endregion*/

    /*#region check and is */
    #checkPrevLinkIsLastLink() {
        let result = false;
        if (this.#getLastLinkMenu().outerHTML() == this.#getPrevLink().outerHTML()) {
            result = true;
        }
        return result;
    }
    #checkMenuIsOpen() {
        let result = false;
        if (this.#getMenu().hasClass("show")) {
            result = true;
        }
        return result;
    }

    #focusIsUp() {
        let result = false;
        if (this.#focusDirection == "up") {
            result = true;
        }
        return result;
    }

    #focusIsDown() {
        let result = false;
        if (this.#focusDirection == "down") {
            result = true;
        }
        return result;
    }
    /*#endregion*/

    /*#region toogle click*/
    #toogleClickMenu(target) {

        this.#setIsOpen();

        if (this.#isOpen == true) {

            this.#isOpen = false;

            const childMenu = this.getTargetParentMenu(target);
            const childBtn = this.getTargetBtnMenu(target);

            if (childMenu.length === 0 && childBtn.length === 0) {

                if (
                    this.getTargetId(target) != this.#idMainMenu
                    && !this.getTarget(target).hasClass("height-top-bar")
                    && this.getTargetId(target) != this.#idBurgerBtn
                ) {

                    this.#closeMenu();
                }

            }
        }
        else {
            this.#isOpen = true;
        }

        this.#setAriaLabelBurger();

    }
    /*#endregion*/

    /*#region Close */
    #closeMenu() {
        this.#Nav.removeClass('show');
        this.#getBurger().addClass('collapsed');
        this.#getBurger().attr('aria-expanded', 'false');
    }
    /*#endregion*/
}